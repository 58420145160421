<template>
    <div class="width_height_full" style="background-color: #F7F8F9;padding-top: 0.2rem">
        <div style="background-color: #FFFFFF;border-radius: 8px;margin: 0.2rem;padding-top:1px;padding-bottom:20px" class="">
                <div v-for="(item,index) in this.deviceChannelsData"
                     :key="index"
                     v-if="item.isUse == 1"
                     @click="toLive(item)"
                     class="position_relative"
                     style="height: 1.97rem;margin: 0px 16px;margin-top: 20px;z-index: 0;">
                    <van-image :src="item.channelPicUrl"
                               class="height_full position_absolute "
                               style="z-index: 1;width: 3.5rem"
                    ></van-image>
                    <van-image v-if="item.channelStatus == 1" :src="require('@/assets/img/site/live_play.png')"
                               class=" position_absolute "
                               style="z-index: 2;width: 1rem;height: 1rem ;top: 0.485rem;left: 1.25rem"
                    ></van-image>
                    <div class=" position_absolute mengBg height_full" style="width: 3.5rem"></div>
                    <div v-if="item.channelStatus == 0" class="position_absolute" style="width: 1rem;top: 0.85rem;left: 1.25rem;z-index: 11;color: white;font-size: 12px;height: 12px;line-height: 12px;text-align: center">离线</div>
                    <div class="position_absolute height_full bold flex align_items_center" style="left: 3.5rem;right: 0;width: 3.5rem;font-size: 17px;background-color: white;color: #333333;">
                        <div class="" style="width: 3rem;word-wrap:break-word;padding: 0 12px"> {{item.channelName}}</div>
                    </div>
                </div>

            </div>

    </div>

</template>

<script>
    import {getDeviceChannels,getLiveAddress} from '@/utils/api'
    import {Toast} from "vant";
    import {uwStatisticAction} from "../../utils/util";
    export default {
        name: "monitoringPage",
        data(){
            return{
                deviceChannelsData:{},
                liveUrl:''
            }
        },
        methods:{
            toLive(item){
                if (item.channelStatus == 0){
                    Toast('设备已离线,请检查设备或退出重新进入刷新页面');
                    return;
                }
                this.toGetLiveAddress(item)
            },
            toGetDeviceChannels() {
                getDeviceChannels({
                    stationNo: this.$route.query.stationNo,
                    pageType:'home'
                }).then(res=>{
                    if(res.code == 200){
                        if(res.data && res.data.businessCode == 200) {
                            this.deviceChannelsData = res.data.channels
                        }
                    }
                })
            },
            toGetLiveAddress(item) {
                getLiveAddress({
                    deviceSerial:item.deviceSerial,
                    channelId:item.channelId
                }).then(res=>{
                    if(res.code == 200){
                        if(res.data && res.data.businessCode == 200) {
                            window.location.href = res.data.videoAddress.hls
                        }
                    }
                })
            },
        },
        mounted() {
            this.toGetDeviceChannels();
            uwStatisticAction('/site/monitoringPage','站点监控')
        }
    }
</script>

<style lang="less" scoped>
.mengBg{
    background-color: rgba(0,0,0,0.4);z-index: 10
}
</style>
